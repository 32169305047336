import TrailyApiInstance from './base';
const BASE_URL = '/admin';

export const getUsersApi = async ({ params }) => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/users`, { params });
  return response;
};

export const createUserApi = async data => {
  const response = await TrailyApiInstance.post(`${BASE_URL}/users`, data);
  return response;
};

export const updateUserApi = async (id, data) => {
  const response = await TrailyApiInstance.put(`${BASE_URL}/users/${id}`, data);
  return response;
};

export const deactivateUserApi = async id => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/users/${id}/deactivate`,
  );
  return response;
};

export const transferOwnerApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/users/${id}/transfer`,
    data,
  );
  return response;
};

export const activateUserApi = async id => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/users/${id}/activate`,
  );
  return response;
};
