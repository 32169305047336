/*
 *
 * SupporterDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import adapter from '../entity-adapter';

import {
  deleteSupporterAct,
  deleteSupporterErrorAct,
  deleteSupporterSuccessAct,
  getSupporterDetailAct,
  getSupporterDetailErrorAct,
  getSupporterDetailSuccessAct,
  resetSupporterDetailAct,
  transferMealAct,
  transferMealErrorAct,
  transferMealSuccessAct,
  updateSupporterAct,
  updateSupporterErrorAct,
  updateSupporterSuccessAct,
} from './actions';

export const initialState = adapter.getInitialState({
  loadingState: 0,
  error: '',
  updateState: 0,
  deleteState: 0,
  mealUpdateState: 0,
  fridayMeals: adapter.getInitialState(),
  saturdayMeals: adapter.getInitialState(),
});

/* eslint-disable default-case, no-param-reassign */
const supporterDetailSlice = createSlice({
  name: 'SupporterDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSupporterDetailAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getSupporterDetailErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getSupporterDetailSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        const supporter = { ...payload };
        const { fridayMeals, saturdayMeals } = supporter;
        supporter.fridayMeals = fridayMeals.map(meal => meal.id);
        supporter.saturdayMeals = saturdayMeals.map(meal => meal.id);
        adapter.setAll(state.fridayMeals, fridayMeals);
        adapter.setAll(state.saturdayMeals, saturdayMeals);
        adapter.addOne(state, supporter);
      })
      .addCase(updateSupporterAct, state => {
        state.error = '';
        state.updateState = 1;
      })
      .addCase(updateSupporterErrorAct, (state, { payload }) => {
        state.error = payload;
        state.updateState = 3;
      })
      .addCase(updateSupporterSuccessAct, (state, { payload }) => {
        state.updateState = 2;
        state.error = '';
        adapter.updateOne(state, {
          id: payload.id,
          changes: payload,
        });
      })
      .addCase(deleteSupporterAct, state => {
        state.error = '';
        state.deleteState = 1;
      })
      .addCase(deleteSupporterErrorAct, (state, { payload }) => {
        state.error = payload;
        state.deleteState = 3;
      })
      .addCase(deleteSupporterSuccessAct, state => {
        state.deleteState = 2;
        state.error = '';
        adapter.removeAll(state);
      })
      .addCase(transferMealAct, (state, { payload }) => {
        const { data, dayId } = payload;
        const { mealId } = data;
        adapter.updateOne(
          dayId === 5 ? state.fridayMeals : state.saturdayMeals,
          {
            id: mealId,
            changes: {
              actionState: 1,
            },
          },
        );
        state.mealUpdateState = 1;
        state.error = '';
      })
      .addCase(transferMealErrorAct, (state, { payload }) => {
        const { mealId, error, dayId } = payload;
        adapter.updateOne(
          dayId === 5 ? state.fridayMeals : state.saturdayMeals,
          {
            id: mealId,
            changes: {
              actionState: 3,
            },
          },
        );

        state.mealUpdateState = 3;
        state.error = error;
        // update state of the meal by id to 0
      })
      .addCase(transferMealSuccessAct, (state, { payload }) => {
        // remove meal from supporter meals
        const { id, day } = payload;
        adapter.removeOne(
          day === 5 ? state.fridayMeals : state.saturdayMeals,
          id,
        );
        state.mealUpdateState = 2;
        state.error = '';
      })
      .addCase(resetSupporterDetailAct, () => initialState);
  },
});

export default supporterDetailSlice.reducer;
