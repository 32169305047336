/**
 *
 * App
 *
 */

import { Alert, AppBar, CssBaseline, Typography } from '@mui/material';
import React, { memo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { useInjectSaga } from 'redux-injectors';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useIntl } from 'react-intl';
import LoginManager from '../../managers/LoginManager';
import theme from '../../themes';
import { REDUCER_KEY } from '../../managers/AuthManager/constants';
import authSaga from '../../managers/AuthManager/saga';

import commonMessages from '../common/messages';

import roleRoutes from './role.routes';

const renderRoutes = routes =>
  routes.map(route => {
    const { path, element, children, id } = route;
    return (
      <Route key={id} id={id} path={path} element={element}>
        {children && renderRoutes(children)}
      </Route>
    );
  });

export function App() {
  useInjectSaga({ key: REDUCER_KEY, saga: authSaga });
  const intl = useIntl();
  const isProduction = process.env.REACT_APP_IS_PROD_BUILD === 'true';

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          preventDuplicate
        >
          <LoginManager>
            <Routes>{renderRoutes(roleRoutes)}</Routes>
          </LoginManager>
        </SnackbarProvider>
      </LocalizationProvider>
      {!isProduction && (
        <AppBar
          position="fixed"
          color="primary"
          sx={{
            top: 'auto',
            bottom: 0,
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <Alert
            severity="error"
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" component="p" textAlign="center">
              {intl.formatMessage(commonMessages.sandboxWarn)}
            </Typography>
          </Alert>
        </AppBar>
      )}
    </ThemeProvider>
  );
}

export default memo(App);
