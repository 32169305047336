import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getMemberDetailApi,
  updateMedicalCertificateApi,
  updateParentalConsentApi,
  updateTagApi,
  updateTshirtSizeApi,
} from '../../api/member.api';
import {
  getMemberDetailErrorAct,
  getMemberDetailSuccessAct,
  updateMedCertSuccessAct,
  updateMedCertErrorAct,
  updateParentalConsentSuccessAct,
  updateParentalConsentErrorAct,
  updateTshirtSizeSuccessAct,
  updateTshirtSizeErrorAct,
  updateTagStateSuccessAct,
  updateTagStateErrorAct,
} from './actions';
import {
  GET_MEMBER_DETAIL,
  UPDATE_MEDICAL_CERT,
  UPDATE_PARENTAL_CONSENT,
  UPDATE_TAG_STATE,
  UPDATE_TSHIRT_SIZE,
} from './constants';

function* fetchMemberDetail({ payload }) {
  try {
    const { data } = yield call(getMemberDetailApi, payload);
    if (data) {
      yield put(getMemberDetailSuccessAct(data));
    } else {
      yield put(getMemberDetailErrorAct({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getMemberDetailErrorAct(errMessage));
  }
}

function* updateMedCertificateState({ payload }) {
  try {
    const { data } = yield call(updateMedicalCertificateApi, payload);
    if (data) {
      yield put(updateMedCertSuccessAct(data));
    } else {
      yield put(updateMedCertErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateMedCertErrorAct(errMessage));
  }
}

function* updateParentalConsentState({ payload }) {
  try {
    const { data } = yield call(updateParentalConsentApi, payload);
    if (data) {
      yield put(updateParentalConsentSuccessAct(data));
    } else {
      yield put(updateParentalConsentErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateParentalConsentErrorAct(errMessage));
  }
}

function* updateTshirtSize({ payload }) {
  try {
    const { data } = yield call(updateTshirtSizeApi, payload);
    if (data) {
      yield put(updateTshirtSizeSuccessAct(data));
    } else {
      yield put(updateTshirtSizeErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateTshirtSizeErrorAct(errMessage));
  }
}

function* updateTagState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateTagApi, payload);
    if (data) {
      yield put(updateTagStateSuccessAct(data));
    } else {
      yield put(
        updateTagStateErrorAct({
          tagId: payloadData.tagId,
          active: !payloadData.active,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateTagStateErrorAct({
        tagId: payloadData.tagId,
        active: !payloadData.active,
        error: errMessage,
      }),
    );
  }
}
// Individual exports for testing
export default function* memberDetailSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_MEMBER_DETAIL, fetchMemberDetail);
  yield takeLatest(UPDATE_MEDICAL_CERT, updateMedCertificateState);
  yield takeLatest(UPDATE_PARENTAL_CONSENT, updateParentalConsentState);
  yield takeLatest(UPDATE_TSHIRT_SIZE, updateTshirtSize);
  yield takeEvery(UPDATE_TAG_STATE, updateTagState);
}
