/* eslint-disable react/prop-types */
/**
 *
 * RoleBasedRoutes
 *
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import ErrorBoundary from '../ErrorBoundary';
import { makeSelectViewRole } from '../../managers/AuthManager/selectors';

function NoAccessText() {
  return (
    <Paper elevation={2}>
      <Alert severity="error">
        <AlertTitle>Access denied</AlertTitle>
        <Typography>You don&apos;t have access to this page.</Typography>
      </Alert>
    </Paper>
  );
}

export function RoleBasedRoute({ roles }) {
  const userRole = useSelector(makeSelectViewRole());

  if (roles && !roles.includes(userRole)) {
    return <NoAccessText />;
  }

  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}

export default RoleBasedRoute;
