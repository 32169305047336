import TrailyApiInstance from './base';
const BASE_URL = '/members';

export const getMemberDetailApi = async memberId => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${memberId}`);
  return response;
};

export const updateMedicalCertificateApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/med-certificate`,
    data,
  );
  return response;
};

export const updateParentalConsentApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/parental-consent`,
    data,
  );
  return response;
};

export const updateTshirtSizeApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/tshirt-size`,
    data,
  );
  return response;
};

export const updateBoardingStatusApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/boarding-status`,
    data,
  );
  return response;
};

export const updateMemberApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(`${BASE_URL}/${id}`, data);
  return response;
};

export const getNotesApi = async ({ memberId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${memberId}/notes`,
    {
      params,
    },
  );
  return response;
};

export const createNoteApi = async ({ memberId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${memberId}/notes`,
    data,
  );
  return response;
};

export const updateNoteApi = async ({ memberId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${memberId}/notes/${id}`,
    data,
  );
  return response;
};

export const updatePinStatusApi = async ({ memberId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${memberId}/notes/${id}/update-pin`,
    data,
  );
  return response;
};

export const deleteNoteApi = async ({ memberId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${memberId}/notes/${id}`,
  );
  return response;
};

export const updateTagApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/update-tag`,
    data,
  );
  return response;
};
