import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the memberDetail state domain
 */

const selectMemberDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by MemberDetail
 */

const makeSelectMemberDetail = () =>
  createSelector(selectMemberDetailDomain, substate => substate);

const makeSelectMember = () =>
  createSelector(selectMemberDetailDomain, substate => substate.member);

const makeSelectError = () =>
  createSelector(selectMemberDetailDomain, substate => substate.error);

const makeSelectLoadingState = () =>
  createSelector(selectMemberDetailDomain, substate => substate.loadingState);

const makeSelectMemberCertState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberCertState,
  );

const makeSelectMemberParentConsentState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberParentConsentState,
  );

const makeSelectMemberTshirtState = () =>
  createSelector(
    selectMemberDetailDomain,
    substate => substate.memberTshirtState,
  );

export default makeSelectMemberDetail;
export {
  selectMemberDetailDomain,
  makeSelectMember,
  makeSelectError,
  makeSelectLoadingState,
  makeSelectMemberCertState,
  makeSelectMemberParentConsentState,
  makeSelectMemberTshirtState,
};
