import React from 'react';
import { Navigate } from 'react-router-dom';

import { PrivateRoute } from '../PrivateRoute';
import Login from '../Login';
import EventsPage from '../EventsPage/Loadable';
import InternalUser from '../InternalUser/Loadable';
import EventsPageManager from '../../managers/EventsPageManager';
import InternalUserManager from '../../managers/InternalUserManager';
import EventDetailManager from '../../managers/EventDetailManager';
import EventDetail from '../EventDetail/Loadable';
import EventPage from '../EventPage/Loadable';
import EventTeamListManager from '../../managers/EventTeamListManager';
import EventTeamList from '../EventTeamList/Loadable';
import EventVolunteerListManager from '../../managers/EventVolunteerListManager';
import EventVolunteerList from '../EventVolunteerList/Loadable';
import EventTeamDetailManager from '../../managers/EventTeamDetailManager';
import EventTeamDetail from '../EventTeamDetail/Loadable';
import VolunteerDetailManager from '../../managers/VolunteerDetailManager';
import VolunteerDetail from '../VolunteerDetail/Loadable';
import VolunteerTagsManager from '../../managers/VolunteerTagsManager';
import VolunteerTags from '../VolunteerTags/Loadable';
import TeamTags from '../TeamTags/Loadable';
import MemberTags from '../MemberTags/Loadable';
import VolunteerGroupsManager from '../../managers/VolunteerGroupsManager';
import VolunteerGroups from '../VolunteerGroups/Loadable';
import MemberDetailManager from '../../managers/MemberDetailManager';
import TeamTagsManager from '../../managers/TeamTagsManager';
import MemberTagsManager from '../../managers/MemberTagsManager';
import MemberDetail from '../MemberDetail/Loadable';
import EventMemberListManager from '../../managers/EventMemberListManager';
import EventMemberList from '../EventMemberList/Loadable';
import EventSupporterList from '../EventSupporterList/Loadable';
import SupporterListManager from '../../managers/SupporterListManager';
import SupporterDetailManager from '../../managers/SupporterDetailManager';
import SupporterDetail from '../SupporterDetail/Loadable';
import AdminDashboardManager from '../../managers/AdminDashboardManager';
import AdminDashboard from '../AdminDashboard/Loadable';
import EventGlobalSetting from '../EventGlobalSetting/Loadable';
import RoleBasedRoute from '../RoleBasedRoute';
import ModuleBasedRoute from '../ModuleBasedRoute';

const VOLUNTEER_MODULE = 'Volunteer';
const SUPPORTER_MODULE = 'Supporter';

const routes = [
  {
    path: '/login',
    id: 'login',
    element: <Login />,
  },
  {
    path: '/forgot-password',
    id: 'forgot-password',
    element: <h1>Forgot password</h1>,
  },
  {
    path: '/*',
    id: 'private-route',
    element: <PrivateRoute />,
    children: [
      {
        id: 'events',
        path: 'events/:status',
        element: (
          <EventsPageManager>
            <EventsPage></EventsPage>
          </EventsPageManager>
        ),
      },
      {
        id: 'event',
        path: 'event/:id',
        element: (
          <EventDetailManager>
            <EventPage></EventPage>
          </EventDetailManager>
        ),
        children: [
          {
            id: 'admin-paths',
            path: '',
            element: <RoleBasedRoute roles={[1, 2]}></RoleBasedRoute>,
            children: [
              {
                id: 'event.home',
                path: 'detail',
                element: <EventDetail></EventDetail>,
              },
              {
                path: 'teams',
                id: 'event.teamlist',
                element: (
                  <EventTeamListManager>
                    <EventTeamList></EventTeamList>
                  </EventTeamListManager>
                ),
              },
              {
                path: 'volunteers',
                id: 'event.volunteerlist.module',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    id: 'event.volunteerlist',
                    element: (
                      <EventVolunteerListManager>
                        <VolunteerGroupsManager>
                          <EventVolunteerList></EventVolunteerList>
                        </VolunteerGroupsManager>
                      </EventVolunteerListManager>
                    ),
                  },
                ],
              },
              {
                path: 'teams/:teamId',
                id: 'event.team.detail',
                element: (
                  <EventTeamDetailManager>
                    <InternalUserManager>
                      <EventTeamDetail></EventTeamDetail>
                    </InternalUserManager>
                  </EventTeamDetailManager>
                ),
              },
              {
                path: 'volunteers/:volunteerId',
                id: 'event.volunteer.detail.module',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    id: 'event.volunteer.detail',
                    element: (
                      <VolunteerDetailManager>
                        <VolunteerGroupsManager>
                          <InternalUserManager>
                            <EventVolunteerListManager>
                              <VolunteerDetail></VolunteerDetail>
                            </EventVolunteerListManager>
                          </InternalUserManager>
                        </VolunteerGroupsManager>
                      </VolunteerDetailManager>
                    ),
                  },
                ],
              },
              {
                path: 'supporters/:supporterId',
                id: 'event.supporter.detail.module',
                element: (
                  <ModuleBasedRoute
                    module={SUPPORTER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    id: 'event.supporter.detail',
                    element: (
                      <SupporterDetailManager>
                        <SupporterListManager>
                          <SupporterDetail></SupporterDetail>
                        </SupporterListManager>
                      </SupporterDetailManager>
                    ),
                  },
                ],
              },
              {
                path: 'members/:memberId',
                id: 'event.member.detail',
                element: (
                  <MemberDetailManager>
                    <MemberDetail></MemberDetail>
                  </MemberDetailManager>
                ),
              },
            ],
          },
          {
            path: 'members',
            id: 'member-onboarder',
            element: <RoleBasedRoute roles={[1, 2, 3, 4]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                id: 'event.memberlisting',
                element: (
                  <EventMemberListManager>
                    <EventMemberList></EventMemberList>
                  </EventMemberListManager>
                ),
              },
            ],
          },
          {
            path: 'supporters',
            id: 'supporter-onboarder',
            element: <RoleBasedRoute roles={[1, 2, 3, 5]}></RoleBasedRoute>,
            children: [
              {
                path: '',
                id: 'event.supporterlist.module',
                element: (
                  <ModuleBasedRoute
                    module={SUPPORTER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: '',
                    id: 'event.suporterList',
                    element: (
                      <SupporterListManager>
                        <EventSupporterList></EventSupporterList>
                      </SupporterListManager>
                    ),
                  },
                ],
              },
            ],
          },
          {
            path: 'setting',
            id: 'setting',
            element: <RoleBasedRoute roles={[1, 2]}></RoleBasedRoute>,
            children: [
              {
                path: 'team-tags',
                id: 'event.setting.teamtags',
                element: (
                  <TeamTagsManager>
                    <TeamTags></TeamTags>
                  </TeamTagsManager>
                ),
              },
              {
                path: 'member-tags',
                id: 'event.setting.membertags',
                element: (
                  <MemberTagsManager>
                    <MemberTags></MemberTags>
                  </MemberTagsManager>
                ),
              },
              {
                path: '',
                id: 'event.volunteersetting.module',
                element: (
                  <ModuleBasedRoute
                    module={VOLUNTEER_MODULE}
                  ></ModuleBasedRoute>
                ),
                children: [
                  {
                    path: 'volunteer-tags',
                    id: 'event.setting.volunteertags',
                    element: (
                      <VolunteerTagsManager>
                        <VolunteerTags></VolunteerTags>
                      </VolunteerTagsManager>
                    ),
                  },
                  {
                    path: 'volunteer-groups',
                    id: 'event.setting.volunteergroup',
                    element: (
                      <VolunteerGroupsManager>
                        <VolunteerGroups></VolunteerGroups>
                      </VolunteerGroupsManager>
                    ),
                  },
                ],
              },
              {
                path: 'global-setting',
                id: 'event.setting.globalsetting',
                element: <EventGlobalSetting></EventGlobalSetting>,
              },
            ],
          },
          {
            path: '*',
            id: 'event.default',
            element: <Navigate replace to="/events/active" />,
          },
        ],
      },
      {
        path: 'users/:id',
        id: 'internal-user-detail',
        element: (
          <InternalUserManager>
            <InternalUser></InternalUser>
          </InternalUserManager>
        ),
      },
      {
        path: 'admin',
        id: 'super-admin',
        element: <RoleBasedRoute roles={[1]}></RoleBasedRoute>,
        children: [
          {
            path: '',
            id: 'admin',
            element: (
              <AdminDashboardManager>
                <AdminDashboard></AdminDashboard>
              </AdminDashboardManager>
            ),
          },
        ],
      },
      {
        path: '*',
        id: 'default-route',
        element: <Navigate replace to="/events/active" />,
      },
    ],
  },
];

export default routes;
