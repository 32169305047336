/*
 *
 * MemberDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  getMemberDetailAct,
  getMemberDetailErrorAct,
  getMemberDetailSuccessAct,
  resetMemberDetail,
  updateMedCertAct,
  updateMedCertErrorAct,
  updateMedCertSuccessAct,
  updateParentalConsentAct,
  updateParentalConsentErrorAct,
  updateParentalConsentSuccessAct,
  updateTagStateAct,
  updateTagStateErrorAct,
  updateTagStateSuccessAct,
  updateTshirtSizeAct,
  updateTshirtSizeErrorAct,
  updateTshirtSizeSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  member: null,
  memberCertState: 0,
  memberParentConsentState: 0,
  memberTshirtState: 0,
};

/* eslint-disable default-case, no-param-reassign */
const memberDetailSlice = createSlice({
  name: 'MemberDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMemberDetailAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getMemberDetailErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getMemberDetailSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.member = payload;
      })
      .addCase(updateMedCertAct, state => {
        state.memberCertState = 1;
        state.error = '';
      })
      .addCase(updateMedCertErrorAct, (state, { payload }) => {
        state.memberCertState = 3;
        state.error = payload;
      })
      .addCase(updateMedCertSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberCertState = 2;
        state.error = '';
      })
      .addCase(updateParentalConsentAct, state => {
        state.memberParentConsentState = 1;
        state.error = '';
      })
      .addCase(updateParentalConsentErrorAct, (state, { payload }) => {
        state.memberParentConsentState = 3;
        state.error = payload;
      })
      .addCase(updateParentalConsentSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberParentConsentState = 2;
        state.error = '';
      })
      .addCase(updateTshirtSizeAct, state => {
        state.memberTshirtState = 1;
        state.error = '';
      })
      .addCase(updateTshirtSizeErrorAct, (state, { payload }) => {
        state.memberTshirtState = 3;
        state.error = payload;
      })
      .addCase(updateTshirtSizeSuccessAct, (state, { payload }) => {
        state.member = { ...state.member, ...payload };
        state.memberTshirtState = 2;
        state.error = '';
      })
      .addCase(updateTagStateAct, (state, { payload }) => {
        const { data } = payload;
        const { tagId } = data;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 1;
          }
        }
        state.error = '';
        state.tagState = 1;
      })
      .addCase(updateTagStateErrorAct, (state, { payload }) => {
        const { tagId, error, isAssigned } = payload;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 3;
            tag.status.active = isAssigned;
          }
        }
        state.tagState = 3;
        state.error = error;
      })
      .addCase(updateTagStateSuccessAct, (state, { payload }) => {
        const { memberTagId: tagId, active } = payload;
        const { member } = state;
        if (member.tags) {
          const tag = member.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 0;
            tag.status.active = active;
          }
        }
        state.tagState = 2;
        state.error = '';
      })
      .addCase(resetMemberDetail, () => initialState);
  },
});

export default memberDetailSlice.reducer;
